@font-face {
  font-family: 'Clash Display';
  src: url('./fonts/ClashDisplay-Bold.ttf') format('truetype'),
  /* Add similar lines for other font weights/styles if needed */
}
@font-face {
  font-family: 'Clash Display Light';
  src: url('./fonts/ClashDisplay-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Clash Display Extralight';
  src: url('./fonts/ClashDisplay-Extralight.ttf') format('truetype');
}
@font-face {
  font-family: 'Clash Display Medium';
  src: url('./fonts/ClashDisplay-Medium.ttf') format('truetype');
}

.App {
  text-align: center;
  font-family: 'Clash Display';

}
.main, body, html {
  height: 100%;
}
#root {
  background: #fff;
}
