.footer-wrapper {
  background: #101010;
  .bold-heading {
    color: #fff;
    font-family: "Clash Display Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 25px;
  }
  .footer-strip {
    padding: 0 100px;
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .footer-container {
    background: #101010;
    display: flex;
    padding: 60px 100px;
    width: 100%;
    .header-div {
      position: unset;
      height: unset;
      width: 50%;

      .left-content {
        display: flex;
        gap: 20px;
      }
    }
    .right-content {
      color: #fff;
      display: flex;
      width: 50%;
      justify-content: space-around;

      .links-container {
        .links {
          color: #fff;
          cursor: pointer;
          font-family: "Clash Display Light";
          font-size: 15px;
          padding-bottom: 15px;
          font-style: normal;
          line-height: normal;
          // text-transform: capitalize;
        }
      }
    }
    .abouts {
      color: #fff;
      font-family: "Clash Display Light";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
    }
  }
  .follow {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrapper {
    background: linear-gradient(114deg, #000 20.39%, #575757 93.03%);
    .footer-strip {
      flex-direction: column-reverse;
      text-align: left;
      align-items: flex-start;
      gap: 30px;
      padding: 40px 50px;
    }
    .footer-container {
      padding: 60px;
      background: unset;
      flex-direction: column;
      .header-div {
        width: 100%;
        .left-content {
          width: 100%;
        }
      }
      .right-content {
        flex-direction: column;
        width: 100%;
        .links-container {
          padding-top: 40px;
        }
      }
    }
    .follow {
      display: block;
    }
  }
}
