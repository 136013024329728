.glass-menu {
  padding: 10px;
  width: 150px;
}


.dropdown-menu-mobile {
    
  .ant-dropdown-menu {
    background: linear-gradient(114deg, #000 20.39%, #575757 93.03%);
    padding: 20px;
    border: none;
    width: 180px;
    backdrop-filter: blur(3px);

    li {
      font-family: "Clash Display Light";
      font-weight: 600 !important;
      font-size: 15px !important;
      color: #fff !important;
      margin-bottom: 20px !important;

      span {
        color: #fff;
      }
      span:hover {
        color: #fff;
        font-weight: 800;
      }
    }
  }
  .ant-dropdown {
    inset: 43px 20px auto auto !important;
  }
}
