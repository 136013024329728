@import "../colors.scss";

.logo-and-text {
  display: flex !important;
  gap: 10px;
  .small-text {
    font-size: 10px;
  }
  .logo-payzeasy {
    font-weight: bold;
    font-size: 22px;
    padding-top: 3px;
  }
  .logo-with-text {
    color: $primary-color;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    justify-content: center;
  }
}

.header-div {
  // position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  padding: 0 100px;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding-left: 0;

  .ant-menu-item {
    padding: 0;
    min-width: 95px;
    text-align: center;
  }
  .ant-menu-title-content {
    font-size: 12px;
    opacity: 0.9;
    font-weight: 600;
  }

  .ant-menu-overflow {
    background: transparent;
    flex: unset;
    justify-content: start;
  }

  .left-content {
    display: flex;
    justify-content: space-between;

    .ant-menu-title-content {
      color: #fff;
    }

    .demo-logo {
      background-image: url("../img/vector-circle.svg");
      background-repeat: no-repeat;
      width: 45px;
      background-position: center;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 17px;
      }
    }
  }
  .right-content {
    .ant-btn {
      width: 116px;
      height: 36px;
      font-size: 13px;
      border: unset;
      font-family: "Inter";
      padding: 10px;
      transition: transform 0.3s ease;
      display: flex;
      justify-content: center;
      font-weight: 600;
      align-items: center;
      border-radius: 10px;
    }
    .ant-btn:hover {
      color: #000;
      transform: scale(1.1);
    }
  }
  .mobile-menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .logo-and-text {
    .logo-payzeasy {
      font-size: 20px;
    }
    .small-text {
      font-size: 9px;
    }
  }
  .header-div {
    padding: 0 15px 0 0;
    .left-content {
      width: 35px;
    }
    .ant-row {
      width: 100%;
    }
    .mobile-menu {
      display: flex;
      justify-content: end;
    }
  }
}
