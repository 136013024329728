.star-rating {
    display: flex;
    gap: 2px;
    font-size: 21px; /* Adjust the size as needed */
  }
  
  .star {
    // text-shadow: 1px 0px 2px #beaeae;
    color: #F9D75B; /* Adjust the color as needed */
  }
  