@import "../../colors.scss";

.home-container {
  .cut-rectangle {
    width: 200px;
    height: 100px;
    background-color: #3498db;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }
  .absolute-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allows interaction with the SVG path underneath */

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Maintain aspect ratio while covering the container */
      }
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  .top-banner {
    padding: 20px 0 64px 100px;
    display: flex;
    justify-content: center;
    background: url("../../img/banner_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
  }
  .right-contents {
    text-align: center;
    width: 84%;
    display: flex;
    // height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  //   Steps section

  .steps-section {
    padding: 100px 0 50px 0;
    background: #f2f2f2;

    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blue-heading {
    color: #48b7ef;
    text-align: center;
    font-family: "Clash Display Medium";
    font-size: 17px;
    font-style: normal;
    line-height: 30.263px;
    letter-spacing: 4.146px;
    text-transform: uppercase;
  }
  .section-heading {
    color: #101010;
    text-align: center;
    font-family: "Clash Display Medium";
    font-size: 50px;
    font-style: normal;
    padding-bottom: 40px;
    line-height: 84px;
  }
  .payment-section {
    padding: 100px 0 50px 0;
    background: #f8f8f8;
    .bottom-section {
      justify-content: center;
      width: 100%;
      display: flex;
      padding: 0 100px;
    }
    .left-section {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .right-section {
      width: 50%;
      .payment-info {
        color: #101010;
        font-family: "Clash Display Light";
        font-style: normal;
        line-height: 40px;
        font-size: 19px;
        font-weight: 700;
      }
      .color-texts {
        .color-bold {
          .bold-text {
          }
        }
      }
      .color-texts {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        gap: 40px;

        .bold-text {
          color: #5274fa;
          font-family: "Clash Display Medium";
          font-size: 24px;
          font-style: normal;
          font-weight: bold;
          line-height: 40px;
        }
      }
    }
  }
  .business-section {
    background: $background-gradient;
    color: #fff;
    display: flex;
    padding: 80px 100px;
    align-items: center;
    gap: 90px;
    background: linear-gradient(114deg, #000 20.39%, #575757 93.03%);
    // background: url('../../img/vector-background-1.svg');

    .left-section {
      .small-info {
        color: #fff;
        font-family: "Clash Display Light";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 34px;
      }
      .point-text {
        color: #fff;
        font-family: "Clash Display Light";
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
      .point-bold {
        color: #48b7ef;
        display: contents;
        font-family: "Clash Display Medium";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .right-section {
      position: relative;
      .iciciBank {
        // display: block;
        // margin: 0px auto;
        // width: 91px;
        // height: 100px;
        // position: absolute;
        // top: 23%;
        // left: -30px;
        // z-index: 99;
      }
    }
    .ant-btn {
      transition: transform 0.3s ease;
    }
    .ant-btn:hover {
      transform: scale(1.1);
    }
  }
  .blue-section {
    background: #48b7ef;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }
  }
  .blue-text-provide {
    color: #48b7ef;
    font-family: "Clash Display Light";
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 4.146px;
    text-transform: uppercase;
    text-align: left;
  }
  .large-heading {
    color: #fff;
    font-family: "Clash Display Medium";
    font-size: 49px;
    font-weight: 600;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    padding: 23px 0 11px;
  }
  .provide-section {
    padding: 100px;
    .left-section {
      img {
        position: absolute;
        z-index: 99;
        bottom: 0px;
        height: 100%;
        left: -3%;
        width: 100%;
      }
    }
    .small-info {
      color: #101010;
      font-family: "Clash Display Light";
      font-size: 23px;
      font-style: normal;
      font-weight: 800;
      text-transform: lowercase;
    }
    .right-section {
      border-left: 1px solid #efefef;
      padding-left: 20px;
      width: 47%;
      .info-cards {
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
        border-radius: 20px;
        background: #fff;
        box-shadow: 5px 5px 20px 0px rgba(136, 136, 136, 0.25);
        margin-bottom: 15px;
        padding-bottom: 31px;
        .card-heading {
          color: #101010;
          font-family: "Clash Display Medium";
          font-size: 19px;
          font-weight: 500;
          padding: 8px 0;
        }
        .card-content {
          font-family: "Clash Display Light";
          font-size: 16px;
          font-weight: 700;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  .reviews-section {
    background: #f2f2f2;
    padding: 90px 0 30px 0;
    .chanting-container {
      width: 100%;
      padding: 40px 0px;
      background: rgb(245, 245, 245);
      .slick-dots {
        display: none !important;
      }

      .name-and-rating {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .section-heading-second {
        margin-top: 50px;
        padding-left: 32px;
        width: 100%;
        font-size: 21px;
        padding: 0 0 9px 35px;
        font-weight: bolder;
      }
      .slick-slide .slick-cloned {
        display: none;
      }

      .carousel-list {
        overflow: hidden !important;
        height: unset !important;
        display: flex !important;
        margin: 10px 0;
      }
      .ant-carousel .slick-prev {
        left: 20px;
        background: #fff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
      }
      .ant-carousel .slick-prev:hover {
        transform: scale(1.1);
      }
      .ant-carousel .slick-next::before {
        content: url("../../img/arrow-caret.svg");
        font-size: 18px;
        height: 3px;
        height: 3px;
        font-weight: initial;
        color: #000;
        top: 37%;
        position: absolute;
      }
      .ant-carousel .slick-prev::before {
        font-size: 18px;
        content: url("../../img/arrow-caret.svg");
        height: 3px;
        font-weight: initial;
        top: 59%;
        left: 38%;
        position: absolute;
        color: #000;
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
      .ant-carousel .slick-prev:hover, .ant-carousel .slick-next:hover {
        transform: scale(1.1);
      }
      .ant-carousel .slick-prev,
      .ant-carousel .slick-next {
        display: flex !important;
        display: flex !important;
        justify-content: center;
        height: 50px;
        width: 50px;
        background: #fff !important;
        z-index: 99999;
        align-items: center;
        border-radius: 50%;
        margin: 0 12px;
        top: 45%;
      }

      .ant-carousel .slick-next {
        right: 20px;
        background: #fff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
      }

      .ant-radio-wrapper-checked {
        transform: scale(1.1);

        img {
          box-shadow: 0px 0px 18px 1px #3c3b3bf0;
        }
        .overlay {
          border: 4px solid #8058c1e6;
        }
      }

      .section-heading {
        width: 100%;
        font-size: 21px;
        padding: 0 0 9px 15px;
        font-weight: bolder;
      }

      .countries-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 21px;
        height: 66vh;
        overflow: auto;
        width: 80%;
        padding: 6px 2px;
        justify-content: center;

        span.ant-radio + * {
          padding: 0;
          height: 100%;
        }

        .country-list {
          position: relative;
          width: 190px;
          height: 120px;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          transition: transform 0.3s ease; /* Add transition for the bouncing effect */

          .ant-radio {
            display: none;
          }

          &:hover {
            transform: scale(1.1); /* Bouncing effect on hover */
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(
              0,
              0,
              0,
              0.5
            ); /* Adjust the alpha value for the desired transparency */
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1; /* Always visible */
            border-radius: 6px;
          }

          .country-name {
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 22px;
            font-size: 21px;
            z-index: 1;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
      }
      .carousel-card {
        width: 35%;
        background: #fff;
        padding: 22px 0;
        border-radius: 10px;

        .top-carousel-container {
          display: flex;
          //   justify-content: center;
          align-items: center;
          gap: 16px;

          img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
          }
          .name-and-position {
            text-align: left;

            .name {
              color: #101010;
              font-family: "Clash Display Medium";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 25px;
              letter-spacing: -0.48px;
            }
            .position {
              font-size: 12px;
              opacity: 0.7;
            }
          }
        }
      }
      .reviews {
        color: #101010;
        font-family: "Clash Display Light";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        border-bottom: 1px solid #e1e1e1;
        margin: 0 25px 20px;
        padding-bottom: 21px;
      }
    }
  }
  .ads-section {
    padding: 70px 100px;
    .inner-section {
      background: #101010;
      border-radius: 10px;
      .icons-and-headings {
        display: flex;
        padding-top: 40px;
        justify-content: space-around;
        .heading-container {
          color: #fff;
          .large-heading {
          }
          .small-heading {
            color: #fff;
            font-family: "Clash Display Light";
            font-size: 18px;
            padding-top: 10px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
      .technical-points {
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 16px;
        gap: 50px;
        font-family: "Clash Display Medium";
        font-weight: 600;
        padding: 30px 0 50px 0;
        .points {
        }
      }
      .bottom-images {
        display: flex;
        justify-content: center;
        gap: 14%;
      }
    }
  }
  .left-contents {
    color: #fff;
    font-family: "Clash Display";
    display: flex;
    flex-direction: column;
    justify-content: center;
    .white-text {
      font-size: calc(100% - -3vw);
      font-family: "Clash Display Light";
      font-weight: 600;
    }
    .blue-text {
      color: $primary-color;
      font-size: 35px;
    }
    .small-text {
      font-family: "Clash Display Light";
      font-weight: 600;
      padding: 6px 0 55px 0;
      opacity: 0.9;
    }
    .ant-btn {
      font-weight: 600;
      border: unset;
      border-radius: 10px;
      height: 38px;
      width: 130px;
      transition: transform 0.3s ease;
    }
    .ant-btn:hover, .ant-btn:focus  {
      color: #000;
      transform: scale(1.1);
    }
    
  }
}
.submit-btn {
  display: flex;
  justify-content: center;

  .ant-btn-primary {
    background: rgb(72, 183, 239);
    color: #000;
    font-family: 'Clash Display Medium';
    width: 171px;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    font-size: 16px;
    height: unset;
  }
  .ant-btn-primary:hover {
    transform: scale(1.1);
  }
}
.ant-modal .ant-modal-close {
  color: #fff;
}
.ant-modal .ant-modal-close:hover, .ant-btn:focus  {
  color: #fff;
}
.ant-modal .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.5);
  padding: 20px 34px;

  .ant-form-item-row {
    flex-direction: column;
  }
  .ant-form-horizontal .ant-form-item-control {
    flex: 0;
  }
  svg {
    fill: '#fff'
  }
  .ant-modal-title {
    color: #fff;
    font-family: 'Clash Display Medium';
    text-align: center;
    font-size: 22px;
    margin-top: 20px;
  }
  .ant-form-item .ant-form-item-explain-error {
    font-family: 'Clash Display Medium';
  }
  .ant-modal-header {
    background: transparent;
  }
  .ant-form-item-label {
    text-align: left;
    font-family: 'Clash Display Medium';
    // margin-bottonm: 25px;
  }
  
  label {
    color: #fff;
  }
}
.provider-right-section {
  width: 50%;
}

@media only screen and (max-width: 900px) {
  .what-we-provides {
    .provider-right-section {
      width: 100%;
      .right-section {
        padding-left: 0;
        padding-top: 70px;
        border: none;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .home-container {
    .top-banner {
      padding: 20px 0 64px 50px;
      .right-contents {
        margin-top: 50px;
        img {
          height: 100%;
          margin-left: -30px;
        }
      }
    }
    .steps-section {
      padding-top: 70px;
      .steps-box {
        justify-content: center;
      }
      .blue-heading {
        padding: 0 30px;
        text-align: left;
      }
      .ant-col {
        justify-content: center;
        padding-left: 30px;
      }
      .section-heading {
        line-height: 43px;
        font-size: 41px;
        text-align: left;
        padding: 0 30px;
        padding-bottom: 50px;
      }
    }
    .reviews-section {
      .blue-heading {
        padding: 0 30px;
        text-align: left;
      }
      .section-heading {
        padding: 0px 30px;
        text-align: left;
        line-height: 43px;
        margin-top: 30px;
      }
      .chanting-container {
        .countries-list {
          width: 100%;
          flex-direction: column;
          padding: 0 30px;
          margin-bottom: 50px;
        }
      }
    }
    .reviews-section {
      .section-heading {
        padding: 0px 20px 0 30px !important;
        line-height: 53px;
        font-size: 45px;
      }
      .chanting-container {
        .name-and-rating {
          flex-direction: column;
        }
        .ant-carousel {
          .slick-next {
            top: 100%;
            right: 25%;
          }
          .slick-prev {
            top: 100%;
            left: 25%;
          }
        }
        .carousel-card {
          width: 100%;
        }
      }
    }
    .payment-manage {
      flex-direction: column-reverse;
      background: linear-gradient(114deg, #000 20.39%, #575757 93.03%);
      .business-section {
        background: unset;
        width: 100%;
        padding: 80px 50px;
      }
      .ant-row {
        width: 100% !important;
      }
      .blue-section {
        background: unset;
      }
    }
    .payment-section {
      .payment-info {
        line-height: 28px;
        font-size: 17px;
      }
      .blue-heading {
        padding: 0px 30px 40px 30px;
        text-align: left;
      }
      .section-heading {
        font-size: 41px;
        line-height: 49px;
        text-align: left;
        padding: 0 30px;
      }
      .bottom-section {
        padding: 0 30px;
        flex-direction: column;
        .left-section {
          width: 100%;
          img {
            padding: 70px 0;
          }
        }
        .right-section {
          width: 100%;
          .color-texts {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
    .provide-section {
      padding: 100px 30px;
    }
    .business-section {
      padding: 80px 50px;
    }
    .ads-section {
      padding: 0;
      background: #101010;
      .lightning-image {
        width: 20%;
      }
      .inner-section {
        padding: 0 30px;
        position: relative;
        .icons-and-headings {
          flex-direction: column;
          .heading-container {
            .large-heading {
              font-size: 49px;
            }
          }
          .money-image {
            display: none;
          }
          .thunder-image {
            width: 32%;
            padding-left: 30px;
          }
        }
      }
      .technical-points {
        flex-direction: column;
        gap: 15px !important;
      }
      .bottom-images {
        .bitcoin-image {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .mobile-image {
        }
      }
    }
  }
}
